<template>
  <div class="Terminos">
<v-container>
<v-row class="card-poly">
  <v-col cols="12" md="12" lg="12">

    <div class="row">
      <div class="col-md-12 col-terms">
        <div class="terms-title">
          <h1 class="color-primary">POLÍTICA DE PRIVACIDAD</h1>
        </div>

        <div class="terms-sub-title">
          <p>El operador del SITIO WEB y el APP</p>
        </div>
        <div class="terms-details">
          <p class="depto-details">
            El SITIO WEB y el APP son operados por OCHO.
          </p>
        </div>
        <div class="terms-details">
          <p class="depto-details">
          Última actualización: 20 de junio 2021
          </p>
        </div>
        <div class="terms-sub-title">
          <p>   TÉRMINOS Y CONDICIONES</p>
        </div>
  
       

<p>La presente Política de Privacidad establece los términos en que OCHO CORPORATION SA DE CV (OCHO) usa y protege la información que es proporcionada por sus usuarios al momento de utilizar su plataforma. OCHO está comprometido con la seguridad de los datos de sus usuarios. Cuando se solicita llenar los campos de información personal con la cual el usuario puede ser identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los términos de este documento. Sin embargo esta Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo que recomendamos y enfatizamos revisar continuamente esta página para asegurar que está de acuerdo con dichos cambios. Al utilizar los servicios que se prestan por nuestra plataforma, el usuario acepta y confía el trato de información personal por parte de OCHO.</p>
<br>
<p><b>1. Recopilación de Datos</b></p>
<br>
<p>Al registrarse un usuario en la plataforma (sitio web, aplicación móvil u otro medio digital propiedad de OCHO), o acceder nuestros servicios o sin haberse registrado o como visitante, usted acepta expresamente que su información personal, sea consultada, procesada, tratada y almacenada por OCHO.</p>
<br>
<p>En caso que específicamente se dicte lo contrario, todos los datos que OCHO le solicita en sus procesos para registro son necesarios para llevar a cabo el registro y/o la prestación de los servicios que OCHO ofrece. El usuario podrá no brindar sus datos personales; mas, esto implica que no le podremos brindar alguno o algunos de los servicios requeridos debido a la naturaleza de los mismos.</p>
<br>
<p>Los usuarios son quienes nos facilitan por sí mismos su información personal en la plataforma y, será su responsabilidad que la información sea real, completa y actualizada en todo momento. </p>
 <br>
 <p>La omisión o inexactitud en alguno de ellos podría implicar la imposibilidad de prestarle los servicios solicitados y/o atender sus solicitudes y facultará a OCHO para tomar acciones tales como pero no limitadas a: eliminar la cuenta del usuario, no brindar los servicios requeridos, o que los datos suministrados por el usuario sean eliminados de la base de datos.</p>
 <br>
  <p><b>2. Información que el Usuario Proporciona</b></p>
  <br>
<p>Recopilamos la información que los usuarios nos proporcionan directamente cuando crean o modifican su cuenta, usa nuestros servicios, se pone en contacto con nuestro servicio de atención al cliente o cuando se comunica de cualquier otra forma con nosotros en OCHO. Esta información puede incluir, pero no está limitada a: nombre, correo electrónico, número de teléfono, dirección postal, foto del perfil, género, estado civil, fecha de cumpleaños, métodos de pago, información no sensible de crédito, los artículos solicitados para servicios de entrega, notas de entrega, el contenido de la comunicación, metadatos y otra información personal que el usuario proporcione.</p>
<br>
 <p><b>3. Información Recopilada a través de Nuestros Servicios</b></p>
<br>
<p>• Ubicación: Recopilamos datos de ubicación exacta o aproximada, si el usuario permite a OCHO acceder a los servicios de ubicación del sistema operativo del dispositivo móvil o a partir de su dirección IP.</p>
<br>
<p>• Operación: Recopilamos datos relacionados con el uso de nuestros servicio, incluyendo el tipo de servicio solicitado, la fecha y el momento de la prestación del servicio, cantidad y volumen de carga o datos, monto cobrado, distancia recorrida, tiempo de entrega y otros detalles relacionados con la operación.</p>
<br>
<p>• Preferencias: Recopilamos información sobre cómo el usuario de la plataforma interactúa con nuestros servicios, preferencias y configuraciones.</p>
<br>
<p><b>4. Uso de información </b></p>
<br>
<p>Podemos usar la información del usuario para actividades como, pero no limitadas a:</p>
<p>• Prestar, activar, mantener, administrar y mejorar nuestros servicios como por ejemplo, proporcionar productos y servicios que el usuario solicite, desarrollar nuevas funciones, prestar atención al cliente tanto a los usuarios como a nuestros Chavos Motoristas, desarrollar opciones de seguridad, autenticar usuarios y enviar actualizaciones de productos y mensajes administrativos.</p>
<br>
<p>• Realizar operaciones internas, como, por ejemplo, mejorar y mantener la seguridad de la plataforma, prevenir el fraude y el abuso de nuestros servicios, solucionar errores de software y problemas de funcionamiento, analizar datos, controlar y analizar las tendencias de uso y de actividad, verificar el cumplimiento de los términos y condiciones que rigen el uso de nuestros servicios. </p>
<br>

<p>• Enviar comunicación que ha sido solicitada o aceptada bajo estos términos por el usuario o que puede ser de su interés, incluida la información sobre los productos y servicios, promociones, noticias y eventos de OCHO o de alguno de sus afiliados, y cuando esté permitido y de conformidad con las leyes aplicables.</p>
<br>
<p>• Personalizar y mejorar los servicios de la plataforma, incluyendo proporcionar o recomendar funciones, contenido, conexiones sociales, referencias y anuncios.</p>
<br>
<p>El usuario autoriza que la información personal sea compartida con terceros, de conformidad con lo establecido en los Términos y Condiciones de la plataforma. Podemos compartir la información que recopilamos sobre el usuario con nuestro personal y/o comunidad para estrictamente mejorar o llevar a cabo la prestación de nuestros servicios.</p>
<br>
<p>El usuario autoriza que la información personal sea divulgada por orden judicial o por orden de alguna autoridad competente con facultades suficientes para requerirla a OCHO o para establecer, ejercer o defender los derechos de OCHO; o en caso que OCHO deba denunciar a las autoridades competentes algún tipo de actividad ilegal por parte del usuario en relación con los servicios brindados través de la plataforma.</p>
<br>
<p>• Con terceros en virtud de una eventual negociación o formalización de una fusión, adquisición, financiamiento, cesión u otras transacciones análogas. En estos casos, OCHO entregará únicamente la información estrictamente necesaria y en cumplimiento de los lineamientos legales establecidos por la legislación aplicable sobre la transferencia de datos personales.</p>
<br>
<p>• Para cualquier otro uso no descrito expresamente en la presente política, que sea previamente notificado y aceptado por el usuario.</p>
<br>
<p>Los datos del usuario no serán cedidos, comunicados ni trasferidos a terceros salvo por lo indicado en la presente política.</p>

<br>
<p>El usuario con su aceptación de la presente política autoriza en forma expresa a OCHO a realizar cualquier operación o conjunto de operaciones mediante procedimientos automatizados o manuales en relación con sus datos personales. OCHO podrá recolectar, llevar a cabo registros, organizar, conservar, modificar, extraer, consultar, utilizar, procesar, transmitir, transferir, cotejar, bloquear, suprimir o destruir la información personal del usuario.</p>
<br>
<p>La transferencia de datos que se realice de conformidad con el presente consentimiento informado se realizará bajo estándares de seguridad que garanticen al usuario y a OCHO que la información será tratada, procesada y almacenada de acuerdo con los fines específicos de la presente y bajo estándares de seguridad que garanticen su integridad y privacidad.</p>
<br>
<p>El usuario, con la lectura y aceptación expresa de esta política, otorga su consentimiento informado para el tratamiento de sus datos según lo que en la presente se indica.</p>
<br>
<p>El usuario registrado cuenta con mecanismos digitales brindados por la plataforma para acceder a la información de su cuenta en cualquier momento, así como a solicitar el acceso, actualización, modificación y eliminación de la información contenida en ella. </p>
<br>
<p>Para eliminar su cuenta de forma permanente, el usuario deberá enviar un mensaje al correo electrónico soporte@ocho.life donde se analizará si podemos retener cierta información sobre el usuario como lo requiera la ley o para fines comerciales legítimos en la medida permitida por la leyes aplicables.</p>
<br>
<p>Respecto a cualquier otro tema relativo a la protección de datos de carácter personal, el usuario puede ponerse en contacto con OCHO mediante el envío de un correo electrónico a soporte@ocho.life.</p>
<br>
<p>Al aceptar nuestra Política de Privacidad y facilitar la INFORMACIÓN DE USUARIO requerida, se entenderá que el USUARIO emite formal Consentimiento Informado, para tratamientos de sus datos según lo establecido en la presente POLITICA. En este sentido, el USUARIO reconoce que: ha leído, entendido y acepta los alcances de esta POLÍTICA; que no tiene dudas relacionadas con la forma, medio y fin para el cual se le ha solicitado la INFORMACIÓN PERSONAL; acepta en forma expresa y voluntaria su registro en nuestra Base de Datos, autoriza el tratamiento de la INFORMACION PERSONAL otorgada, y reconoce que fue informado de los medios por los cuales podrá darse de baja de nuestra Base de Datos y/o controlar la información que proporciona a OCHO.</p>
<br>
 <p><b>5. Uso de Herramientas Tecnológicas</b></p>
<br>
<p>Cuando el usuario visita nuestra plataforma algunos de los datos de navegación se almacenan en nuestros servidores. Estos datos permiten optimizar nuestros servicios, personalizar y mejorar la experiencia del usuario en nuestra plataforma</p>
<br>
<p><b>5.1 Cookies </b></p>
<br>
<p>La plataforma utiliza Cookies para almacenar en el disco duro del dispositivo del usuario al navegar en un sitio de internet específico, el cual permite intercambiar información de estado entre dicho sitio y el navegador del usuario. La información de estado puede revelar medios de identificación de sesión, autenticación o preferencias del usuario.</p>
    <br>
    <p><b>5.2 Web Server Logs</b></p>
<br>
<p>La plataforma utiliza Web Server Logs para grabar en una base de datos de todos los acontecimientos (eventos o acciones) que de procesos para  formar evidencia del comportamiento del sistema.</p>
<br>
<p><b>5.3 Geolocalización</b></p>
<br>
<p>La plataforma utiliza la geolocalización para obtener la ubicación geográfica real del teléfono móvil o ordenador conectado a Internet del usuario y de esta forma poder enviar los productos hasta el destino final.</p>
<br>
<p>Las herramientas anteriormente descritas son utilizadas para analizar la forma en que el usuario interactúa con nuestra plataforma, y poder mejorar su experiencia a través de su recorrido en la misma.</p>
<br>
<p>Por la manera en que funciona la plataforma, si el usuario desactiva o bloquea la utilización de estas herramientas, no será posible llevar a cabo ciertas funciones que requieren conocer la ubicación del usuario en la plataforma.</p>
<br>
<p><b>6. Enlaces a Terceros</b></p>
<br>
<p>La plataforma contiene enlaces a otros sitios y aplicaciones para su normal funcionamiento. Una vez que el usuario abandona nuestra plataforma, ya no tenemos control sobre el sitio al que es redirigido y por tanto OCHO no es responsable de los términos o privacidad ni de la protección de sus datos en esos otros sitios terceros. Dichos sitios están sujetos a sus propias políticas de privacidad por lo cual es recomendable que los consulte para confirmar que usted está de acuerdo con estas.</p>
<br>
<p><b>7. Seguridad de los Datos</b></p>
<br>
<p>Los  datos personales de los usuarios son almacenados en una base de datos propiedad de OCHO, bajo los estándares de seguridad esperados por la industria para cumplir con la integridad y seguridad de la información personal tratada.</p>
<br>
<p>OCHO mantiene los niveles de seguridad de protección de sus datos conforme a la legislación aplicable, relativo a las medidas de seguridad de las bases de datos que contengan datos de carácter personal y ha establecido todos los medios técnicos razonables, a su alcance, para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos que el usuario comunique a OCHO, sin perjuicio de informarle de que las medidas de seguridad en Internet no son infalibles.</p>
<br>
<p>
  A través de nuestra plataforma no se guarda información sensible de tarjeta de crédito ni débito de ninguno de los tarjetahabientes. Esta información, en ningún momento será retenida, divulgada o registrada, sino que utiliza procesadores terceros de pago para almacenarla e intercambiar la misma por un token válido únicamente dentro de la plataforma.
</p>
<br>
<p><b>8. Liberación de Responsabilidad</b></p>
<br>
<p>El usuario reconoce y acepta que el uso de la plataforma se realiza en todo momento bajo su entero riesgo y responsabilidad. OCHO no será responsable por el uso indebido que pueda realizarse de estos medios de comunicación y/o tecnologías.</p>
<br>
<p>OCHO no será responsable por daños y perjuicios de cualquier tipo causados en los equipos electrónicos (cómputo, teléfono, tableta, u otros) del usuario por virus, gusanos o cualquier otro elemento dañino. El USUARIO reconoce que el uso de la red Internet supone la asunción de un riesgo de que sus equipos informáticos puedan ser afectados por los elementos antes enunciados. A tal efecto, corresponde al USUARIO, en todo caso, la disponibilidad de herramientas adecuadas para la detección y eliminación de programas electrónicos dañinos que puedan suponer un riesgo para su equipo de cómputo.</p>
<br>
<p>OCHO no tendrá responsabilidad por la falta de ejecución de sus obligaciones cuando medie fuerza mayor, caso fortuito o la entrada en vigencia de legislación gubernamental, regulaciones o restricciones de cualquier otra índole que le impidan la ejecución de sus obligaciones.</p>
<br>
<p><b>9. Actualización en Políticas de Seguridad y Privacidad</b></p>
<br>
<p>OCHO se reserva la posibilidad de realizar cambios o ajustes a la presente política de. Al realizarse ajustes a la presente política se le notificará a los usuarios al momento de utilizar la plataforma o por alguno de los medios de contacto indicados por el usuario para recibir comunicaciones de parte de OCHO.</p>
      </div>
    </div>


  </v-col>
</v-row>




</v-container>




  </div>
</template>
<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Politicas",
  components: {},
  mounted: function () {
    window.scrollTo(0, 0);

    // gsap.to('.phones', {x: 500, duration: 3})
  },
};
</script>
<style lang="scss">
.Terminos {
  width: 100%;
  padding-top: 150px;
  padding-bottom: 150px;
  background-image: url('../assets/banners-poly.jpg');
  background-size: contain;

  height: auto;
  position: relative;
  background-color: rgb(255, 255, 255);
}
.terms-title {
  font-family: "Poppis-bold";
  font-size: 30px;
}
.terms-sub-title {
  p {
    font-family: "poppins-bold";
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.card-poly{
  background-color: #fff;
  border-radius: 1em;
  box-shadow: #2c2c2c3d 0px 10px 30px;
  padding: 2em;
}
</style>